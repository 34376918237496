import "./FileSelector.scss"

import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react"

import { classWithModifiers, toggleState } from "@/utils/common"
import useFilePreview from "@/utils/hooks/useFilePreview"
import { triggerReactInput } from "@/utils/react"
import FileUtils, { FileHash } from "@/utils/transform/file"

import { FileSelectorBaseProps } from "./types"

import Expander from "../../extrinsic/Expander/Expander"
import Icon from "../Icon/Icon"


interface FileSelectorMultipleProps extends FileSelectorBaseProps {
  value?: File[]
  onChange?(value?: File[]): void
  filesAmmount?: number
}

function FileSelectorMultiple(props: FileSelectorMultipleProps) {
  const [expanded, setExpanded] = useState<boolean>(true)

  const [filesLocal, setFiles] = useState<File[]>([])
  const [errorImage, setErrorImage] = useState(false)
  const files = useMemo(() => props.value ?? filesLocal, [props.value, filesLocal])

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef.current == null) return
    inputRef.current.files = FileUtils.toFileList(props.value ?? [])
  }, [props.value])

  /**
   * Interrupts event spread, dedupes files and dispatches a custom event with all accumulated files.
   *
   * Non-user-agent (custom) events are ignored in this callback to prevent infinite loop.
   */
  async function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (!event.isTrusted) return
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    event.bubbles = false
  
    const target = event.currentTarget
  
    const newFiles = [...target.files ?? []]
    const currentFilesCount = files.length
    const totalFilesCount = currentFilesCount + newFiles.length
    const maxFiles = props.filesAmmount ?? 5
  
    if (totalFilesCount > maxFiles) {
      setErrorImage(true)
      return
    }
  
    const newFilesMap = await FileHash.toSHA1Map([...files, ...newFiles])
    const updatedFiles = [...newFilesMap.values()]
  
    setFiles(updatedFiles)
    props.onChange?.(updatedFiles)
  
    triggerReactInput(target, "files", FileUtils.toFileList(updatedFiles))
    setErrorImage(false)
  }
  

  function onClear() {
    setFiles([])

    if (inputRef.current == null) return
    triggerReactInput(inputRef.current, "value", "")
  }

  if (files.length === 0) {
    return <FileSelectorMultipleSingle {...props} file={files[0]} onChange={onChange} onClear={onClear} errorImage={errorImage} filesAmmount={props.filesAmmount ?? 5} />
  }

  function removeFile(other: File) {
    const newFiles = files.filter(file => file !== other)
    setFiles(newFiles)

    if (inputRef.current == null) return
    triggerReactInput(inputRef.current, "files", FileUtils.toFileList(newFiles))
  }

  return (
    <div className={classWithModifiers("file-selector-multiple", props.disabled && "disabled")}>
      <div className="file-selector-multiple__header">
        {props.label && (
          <button className="file-selector-multiple__label" type="button" onClick={toggleState(setExpanded)}>{props.label}</button>
        )}
        <label className={classWithModifiers("file-selector-multiple__add", expanded && "expanded")}>
          <Icon name="square-plus" />
          <input className="file-selector__input" type="file" accept={props.accept} multiple name={props.name} onChange={onChange} ref={inputRef} />
        </label>
        <button className={classWithModifiers("file-selector-multiple__arrow", expanded && "expanded")} type="button" onClick={toggleState(setExpanded)}>
          <Icon name="arrow-up" />
        </button>
      </div>

      <Expander expanded={expanded}>
        <div className="file-selector-multiple__files">
          {files.map((file, index) => (
            <FileSelectorFile file={file} index={index} setErrorImage={setErrorImage} onRemoveAnimationEnd={removeFile} key={FileUtils.getMetaId(file)} />
          ))}
          {errorImage && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <p style={{ color: "#FA7A7A" }}>{`*You can upload up to ${props.filesAmmount ? "1 file." : "5 files."}`}</p>
            </div>
          )}
        </div>
      </Expander>
    </div>
  )
}

export default FileSelectorMultiple


interface FileSelectorMultipleSingleProps extends FileSelectorBaseProps {
  file?: File
  onClear?(): void
  onChange?(event: ChangeEvent<HTMLInputElement>): void
  errorImage?: boolean
  filesAmmount?: number
}

function FileSelectorMultipleSingle(props: FileSelectorMultipleSingleProps) {
  return (
    <div className={classWithModifiers("file-selector", props.disabled && "disabled")}>
      {props.label && (
        <div className="file-selector__label">{props.label}</div>
      )}
      <label className="file-selector__container">
        <div className="file-selector-multiple__field">
          <div className={classWithModifiers("file-selector__appearance", !!props.file && "filled")}>{props.file?.name ?? props.placeholder ?? "Choose file"}</div>
        </div>

        <input className="file-selector__input" type="file" accept={props.accept} multiple name={props.name} onChange={props.onChange} />
        <button className="file-selector-empty" type="button" onClick={props.onClear}>
          <Icon className="file-selector-empty__icon" name="trash-bin" />
        </button>
      </label>

      {props.errorImage && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <p style={{ color: "#FA7A7A" }}>{`*You can upload up to ${props.filesAmmount ? "1 file." : "5 files."}`}</p>
        </div>
      )}
    </div>
  )
}


interface FileSelectorFileProps {
  /**
   * Required for "swipe-in" animation.
   */
  index?: number
  file: File
  onRemove?(file: File): void
  /**
   * The same as `onRemove` but waits for swiping transition to end.
   */
  onRemoveAnimationEnd?(file: File): void
  setErrorImage?(value: boolean): void
}

function FileSelectorFile(props: FileSelectorFileProps) {
  const [removed, setRemoved] = useState(false)

  function onRemove() {
    setRemoved(true)
    props.onRemove?.(props.file)
    props.setErrorImage?.(false)
  }

  function onRemoveAnimationEnd() {
    if (!removed) return

    props.onRemoveAnimationEnd?.(props.file)
  }

  const fileURL = useFilePreview(props.file)

  return (
    <div className={classWithModifiers("file-selector-file", removed && "removed")} style={{ "--index": props.index }} onAnimationEnd={onRemoveAnimationEnd}>
      <img src={fileURL} alt="file preview" className="file-selector-file__preview" />
      <div className="file-selector-file__name">
        <span>{props.file.name}</span>
      </div>
      <button className="file-selector-empty" type="button" onClick={onRemove}>
        <Icon name="trash-bin" />
      </button>
    </div>
  )
}
